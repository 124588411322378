@tailwind base;
@tailwind components;
@tailwind utilities;

html * {
  font-family: Roboto, Arial, Helvetica, sans-serif;

  border: 1px dashed #cccccc;
  margin: 5px;
  padding: 0px;
}

li * {
  display: flex;
  justify-content: center;
  align-items: center;
}
